* {
  box-sizing: border-box;
}
html {
  color: #222;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}
a,
button {
  color: #222;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a:hover {
  color: #064ca3;
}
h2 {
  font-weight: bold;
  font-size: 22px;
  margin: 0 0 20px;
}
#root {
  background: #7b858c url(../assets/pattern_metal_02.png)repeat;
  background-size: 200px 200px;
}
main {
  max-width: none;
  width: 100%;
  padding: 40px 40px 70px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
/* ボタン */
[class*="btn_"],
button {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  background: #064ca3;
  border: 1px solid #064ca3;
  display: inline-block;
  padding: 8px 32px;
  border-radius: 3px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: 0px 7px 7px -3px rgba(0, 0, 0, 0.2),
    0px 2px 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 7px -3px rgba(0, 0, 0, 0.2),
    0px 2px 1px 1px rgba(0, 0, 0, 0.1);
}
[class*="btn_"]:hover,
button:hover {
  color: #064ca3;
  background: #fff;
}
[class*="btn_"]:active,
button:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
[class*="btn_"][class*="line"] {
  color: #064ca3;
  border: 2px solid #064ca3;
  background: #fff;
}
[class*="btn_"][class*="line"]:hover {
  color: #fff;
  background: #064ca3;
}
[class*="btn_"][class*="line"][class*="gray"] {
  color: #555;
  border: 2px solid #555;
  background: #fff;
}
[class*="btn_"][class*="line"][class*="gray"]:hover {
  color: #fff;
  background: #555;
  border-color: #555;
}
[class*="btn_"][class*="gray"] {
  color: #333;
  background: #ddd;
  border: 1px solid #ddd;
}
[class*="btn_"][class*="gray"]:hover {
  border-color: #333;
}
[class*="btn_"][class*="small"] {
  font-size: 12px;
}
[class*="btn_"][class*="thin"] {
  padding-top: 4px;
  padding-bottom: 4px;
}
[class*="btn_"][class*="thick"] {
  padding-top: 12px;
  padding-bottom: 12px;
}
[class*="btn_"][class*="narrow"] {
  padding-left: 16px;
  padding-right: 16px;
}
[class*="btn_"][class*="wide"] {
  padding-left: 40px;
  padding-right: 40px;
}
[class*="btn_"][class*="full"] {
  text-align: center;
  width: 100%;
}
button:disabled {
  color: #fff !important;
  pointer-events: none;
  background: #bac1c6 !important;
  border-color: #bac1c6 !important;
  -webkit-box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2) inset;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2) inset;
}

/* ボタンエリア */
.btnarea_center {
  text-align: center;
}
.btnarea_right {
  text-align: right;
  flex-shrink: 0;
}
.btnarea_container {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* General App styling */
.App-intro {
  font-size: large;
}
.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  min-width: 1000px;
}

footer {
  color: #999;
  font-size: 10px;
  font-weight: 300;
  text-align: center;
  width: 100%;
  padding: 10px 0;
  background: #333 url(../assets/pattern_noise.png)repeat;
  background-size: 50px 50px;
  -webkit-box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.15) inset;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.15) inset;
}
.ReactVirtualized__Table__Grid {
  outline: none;
}
/* !------------ Header style*/

.App-header {
  padding: 12px 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  background: #fff;
  position: relative;
  -webkit-box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.3);
}

.header_logo {
  max-width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header_logo img {
  max-width: 100%;
  width: 160px;
}

nav {
  display: -webkit-flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1 auto;
}
nav ul {
  margin: 0 -20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
nav li {
  padding: 0 20px;
}

nav button,
nav a {
  width: auto;
}
nav a {
  letter-spacing: 0.05em;
  font-size: 13px;
  font-weight: 600;
}

/* !---------- General utilities */
.autoflex {
  flex: auto;
}
.maxH {
  height: 100%;
}

/* フォーム関連  */
.input_wrap {
  display: flex;
  flex-wrap: nowrap;
}
.input_wrap > * {
  flex: 1 1 auto;
  width: 100%;
}

/* セレクト */
select {
  width: 100%;
}

/* パンくず */
.bread {
  font-size: 12px;
  font-weight: 300;
  padding: 3px 40px;
  background: #333 url(../assets/pattern_noise.png)repeat;
  background-size: 50px 50px;
  -webkit-box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.2) inset;
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.2) inset;
}
.bread ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.bread li {
  padding: 0 8px;
}
.bread a,
.bread p {
  color: #aaa;
  font-size: 12px;
  position: relative;
}

/* !-----　ユニット ----- */
[class*="unit_"] {
  color: #222;
  font-weight: 600;
  font-size: 12px;
  padding: 0 3px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  flex: 0 0 2.5em;
}
[class*="unit_1"] {
  -webkit-flex: 0 0 1em;
  flex: 0 0 1em;
}
[class*="unit_2"] {
  -webkit-flex: 0 0 2em;
  flex: 0 0 2em;
}
[class*="unit_3"] {
  -webkit-flex: 0 0 3em;
  flex: 0 0 3em;
}
[class*="unit_4"] {
  -webkit-flex: 0 0 4em;
  flex: 0 0 4em;
}
[class*="unit_5"] {
  -webkit-flex: 0 0 5em;
  flex: 0 0 5em;
}
[class*="unit_6"] {
  -webkit-flex: 0 0 6em;
  flex: 0 0 6em;
}
[class*="unit_7"] {
  -webkit-flex: 0 0 7em;
  flex: 0 0 7em;
}
[class*="unit_8"] {
  -webkit-flex: 0 0 8em;
  flex: 0 0 8em;
}
[class*="unit_9"] {
  -webkit-flex: 0 0 9em;
  flex: 0 0 9em;
}
[class*="unit_"][class*="left"] {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  padding-left: 0.3em;
}
[class*="unit_"][class*="right"] {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  padding-right: 0.3em;
}
[class *="unit_"][class*="center"] {
  -webkit-justify-content: center;
  justify-content: center;
  padding: 0 0.5em;
}

/* !-----　パーツ ----- */
input:not([type="checkbox"]),
select,
textarea,
input[type="file"]:before {
  font: 300 12px/1.5em "noto sans japanese";
  /*border: 1px solid #ddd;*/
  border-radius: 3px;
  padding: 4px 8px;
  background: #fff;
  flex: 1 1 100%;
  width: 100%;
}
input:not([type="submit"]),
select {
  height: 30px;
}
input,
select,
button,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
textarea {
  min-height: 100px;
  resize: none;
}
/* プレイスホルダー */
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #ccc;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #ccc;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #ccc;
}

/* !-----　パーツ横幅指定 ----- */
.input_50 {
  max-width: 50px;
}
.input_75 {
  max-width: 75px;
}
.input_100 {
  max-width: 100px;
}
.input_120 {
  max-width: 120px;
}
.input_140 {
  max-width: 140px;
}
.input_150 {
  max-width: 150px;
}
.input_200 {
  max-width: 200px;
}
.input_250 {
  max-width: 250px;
}
.input_300 {
  max-width: 300px;
}
.input_350 {
  max-width: 350px;
}
.input_400 {
  max-width: 400px;
}
.input_450 {
  max-width: 450px;
}
.input_500 {
  max-width: 500px;
}
.input_550 {
  max-width: 550px;
}
.input_600 {
  max-width: 600px;
}
.input_max {
  max-width: 100vw;
}

/* !---------- セレクト ---------- */
select {
  width: 100%;
  padding-right: 20px;
  -webkit-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}
.select_wrap {
  width: 100%;
  position: relative;
}
.select_wrap:before {
  color: #2c9cfb;
  content: "\f107";
  font: normal 13px/1em "fontawesome";
  position: absolute;
  top: 50%;
  right: 5px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
}

/* !---------- チェックボックス ---------- */
.checkbox_wrap,
.radio_wrap {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}
.checkbox_wrap input,
.radio_wrap input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}
.checkbox_wrap label,
.radio_wrap label {
  font: 400 12px/1.3em "noto sans japanese";
  position: relative;
  padding: 0 0 0 24px;
  height: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.checkbox_wrap label:before,
.checkbox_wrap label:after,
.radio_wrap label:before,
.radio_wrap label:after {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.radio_wrap label:before,
.radio_wrap label:after {
  border-radius: 50%;
}
.checkbox_wrap label:before,
.radio_wrap label:before {
  display: block;
  background: #fff;
  border: 1px solid #ccc;
}
.checkbox_wrap label:after {
  color: #2c9cfb;
  content: "\f00c";
  font: normal 12px/16px "fontawesome";
  text-align: center;
  width: 16px;
  height: 16px;
  display: none;
}
.radio_wrap label:after {
  content: "\f111";
  font: normal 10px/16px "fontawesome";
  text-align: center;
  width: 16px;
  height: 16px;
  -webkit-transform: scale(0.8) translateY(-50%);
  transform: scale(0.8) translateY(-50%);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  display: none;
}
input[type="checkbox"]:checked + label::after,
input[type="radio"]:checked + label::after {
  display: block;
}
/* ラベルが並ぶ場合 */
input[type="checkbox"] + label,
input[type="radio"] + label {
  margin-right: 20px;
}
/* ラベルが並ぶ場合(crasconavi) */
.wrapper_radio + .wrapper_radio {
  margin-left: 20px;
}

/* ラベルがない場合 */
.checkbox_wrap .label-none {
  padding: 0 0 0 16px; /* boxの幅分 */
}

/* ====================== 検索バー ====================== */
#search-bar {
  /* padding: 30px 0 10px; */
  display: flex;
  flex-wrap: nowrap;
}
div.keyword-input {
  flex: 1 1 auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
div.keyword-input label,
div.keyword-input span {
  font-size: 13px;
  font-weight: 600;
}
div.keyword-input input {
  margin: 0 20px 0 10px;
}
div.action-buttons {
  flex: 0 0 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.action-buttons [class*="btnarea"] > [class*="btn_"] {
  margin: 0 0 0 3px;
}

#search-bar > div.action-buttons > a > button,
#search-bar > div.action-buttons > button {
  padding: 4px 8px;
}
#search-bar > div.action-buttons > button {
  margin: 0 0 0 5px;
}
#search-bar > div.action-buttons > button:disabled {
  color: #fff !important;
  pointer-events: none;
  background: #bac1c6 !important;
  border-color: #bac1c6 !important;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3) inset;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3) inset;
}

/* ====================== ページング ====================== */
.paging {
  display: flex;
  align-items: flex-end;
  margin: 15px 0 0;
}
.paging span {
  display: inline-block;
  font-size: 11px;
  font-weight: 300;
  margin: 0 0 0 10px;
}
.paging ul {
  display: flex;
  flex-wrap: wrap;
}
.paging li {
  padding: 0 2px;
}
.paging a {
  color: #999;
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 50%;
  background: #f3f3f3;
  border: 1px solid transparent;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paging a:hover {
  color: #064ca3;
  border-color: #064ca3;
}
.paging a.active {
  color: #fff;
  background: #064ca3;
  border-color: #064ca3;
}

/* テーブルの中身の幅  */
.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 5px !important;
  margin-left: 5px !important;
}
.ReactVirtualized__Table__headerTruncatedText {
  -o-text-overflow: inherit !important;
  text-overflow: inherit !important;
  white-space: normal !important;
}
.ReactVirtualized__Table__row {
  border-bottom: 1px dotted #ccc;
}

/* テーブル ===========================  */
th,
td {
  padding: 8px 3px;
}
th {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2em;
  background: #f3f3f3;
}
td {
  font-weight: 300;
  font-size: 12px;
}

/* Notifier ========================= */
#notifier {
  position: fixed;
  bottom: 25px;
  left: 25px;
  display: flex;
  flex-flow: column-reverse;
}

#notifier > .error-message {
  background-color: #f6cdcdeb;
  padding: 20px;
  border-radius: 10px;
  margin: 15px 0 0;
  max-width: 300px;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.3);
  transform: translateY(-20px);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

#notifier > .error-message > h4 {
  font-weight: bold;
}

#notifier > .error-message > p {
  padding-left: 10px;
}

#notifier > .error-message.entering {
  opacity: 0;
  transform: translateY(0);
}

#notifier > .success-message {
  background-color: rgba(216, 246, 205, 0.92);
  padding: 20px;
  border-radius: 10px;
  margin: 15px 0 0;
  max-width: 300px;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.3);
  transform: translateY(-20px);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

#notifier > .success-message > h4 {
  font-weight: bold;
}

#notifier > .success-message > p {
  padding-left: 10px;
}

#notifier > .success-message.entering {
  opacity: 0;
  transform: translateY(0);
}


/* !-----　トップページ3ステップ検索フォーム ----- */
.step_form {
  margin: 20px 0 10px;
  display: -webkit-flex; display: flex;
  -webkit-flex-wrap: nowrap; flex-wrap: nowrap;
  width: 100%;
}
.step_form .category {
  font: 600 14px/1em 'noto sans japanese';
  -webkit-flex: 0 0 60%;
  flex: 0 0 60%;
  display: block;
  border: 1px solid #222;
  border-radius: 3px;
  margin: 0 10px 0 0;
  background: #f5f5f5;
  -webkit-flex: 1 1 auto; flex: 1 1 auto;
  display: -webkit-flex; display: flex;
  -webkit-align-items: center; align-items: center;
  -webkit-justify-content: center; justify-content: center;
}
.step_form .btnarea {
  margin: 0 0 0 10px;
  -webkit-flex: 0 0 100px; flex: 0 0 100px;
}
.step_form .btnarea .btn_ {
  width: 100%;
  text-align: center;
}

/* !-----　トップページ3ステップ検索 ----- */
.step_select {
  width: 100%;
  display: none;
}
.step_select.on {
  display: block;
}
.list_wrap {
  border: 2px solid #000;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  height: 220px;
  position: relative;
}
.list_wrap ul {
  overflow: auto;
}
.list_wrap li {
  border-bottom: 1px solid #ddd;
}
.list_wrap p {
  cursor: pointer;
  font: 500 14px/1em 'noto sans japanese';
  padding: 12px;
  padding-right: 50px;
  position: relative;
  background: #fff;
}
.list_wrap p.current {
  background: #bac1c6;
}
.list_wrap p:hover {
  color: #fff;
  background: #064ca3;
}

/* ステップ2に矢印配置  */
.list_wrap .step_01 p:before,
.list_wrap .step_02 p:before {
  content: "";
  width: 13px;
  height: 13px;
  background: url(../assets/ico_right.svg)no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.list_wrap .step_01 p:hover:before,
.list_wrap .step_02 p:hover:before {
  background: url(../assets/ico_right_white.svg)no-repeat center center;
}


/* 階層リスト */
.list_wrap > div {
  overflow: auto;
  height: 220px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
.step_02,
.step_03 {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

/* ボタンエリア */
.list_wrap .btnarea {
  text-align: center;
  background: #333;
  height: 34px;
  display: -webkit-flex; display: flex;
  -webkit-align-items: center; align-items: center;
  -webkit-justify-content: center; justify-content: center;
  position: relative;
}
.list_wrap .btnarea .ttl {
  color: #fff;
  font: 600 13px/1em 'noto sans japanese';
}
.list_wrap .btnarea [class*="back"],
.list_wrap .btnarea .select {
  cursor: pointer;
  color: #fff;
  display: inline-block;
  font: 500 13px/1em 'noto sans japanese';
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 4px 8px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.list_wrap .btnarea [class*="back"]:hover,
.list_wrap .btnarea .select:hover {
  color: #333;
  background: #fff;
}
.list_wrap .btnarea [class*="back"] {
  left: 10px;
}
.list_wrap .btnarea .select {
  right: 10px;
}

/* step2 */
.list_step_02 .step_01{
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}
.list_step_02 .step_02 {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

/* step3 */
.list_step_03 .step_02 {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}
.list_step_03 .step_03 {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.list_step_03 .step_01 {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.no-rows {
  margin: 10px;
  color: #555;
  font-weight: 200;
  font-size: 11px;
}
