div.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}
#main-login{
  text-align: center;
}
#main-login .header_logo {
  max-width: 200px;
  margin: 0 auto;
}
#main-login .header_logo span {
  color: #555;
  font-size: 13px;
  font-weight: 500;
  display: block;
  margin: 8px 0 0;
  text-align: center;
}
#main-login .explain {
  margin: 40px 0 0;
  font-size: 13px;
}
.container main {
  max-width: 400px;
  align-items: center;
  padding: 60px 30px;
  background: #fff;
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  -webkit-box-shadow: 5px 48px 20px 0px rgba(0,0,0,0.1), 25px 28px 2px -7px rgba(0,0,0,0.05), -15px 36px 2px -7px rgba(0,0,0,0.05);
  box-shadow: 5px 48px 20px -7px rgba(0,0,0,0.1), 25px 28px 2px -7px rgba(0,0,0,0.03), -15px 36px 2px -7px rgba(0,0,0,0.03);
}
#login-form {
  margin: 10px 0 0;
  width: 100%;
  padding: 1em;
}
#login-form > div:not([class*="btnarea"]):not(.login_other) {
  margin: 20px 0 0;
  width: 100%;
  position: relative;
}
#login-form div:not([class*="btnarea"]):not(.login_other):before {
  content: "";
  width: 20px;
  height: 20px;
  background: url(../assets/ico_mail.png) no-repeat center center;
  background-size: contain !important;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}
#login-form div.password:not([class*="btnarea"]):not(.login_other):before {
  background: url(../assets/ico_key.png) no-repeat center center;
}

#login-form input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 5px 5px 5px 40px;
}
#login-form label{
    width:10%;
}

#login-form [class*="btnarea"] {
  width: 100%;
  margin: 30px 0 0;
}

/* パスワード忘れなど {} */
.login_other {
  color: #aaa;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  margin: 30px 0 0;
  width: 100%;
}
.login_other p + p {
  margin: 10px 0 0;
}
.login_other a:hover {
  text-decoration: underline;
}

/* エラーメッセージ */
.errorMessage {
  font-size: 12px;
  margin-top: 10px;
}
