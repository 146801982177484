/* 検索ページ */
* {
  box-sizing: border-box;
}
#main-search {
    padding: 40px 40px 70px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.page-title {
    display: inline-block;
}
#inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
#keySearch, #stepSearch {
  width: calc((100% - 20px) / 2);
}
#keySearch, #stepSearch, #results {
  padding: 40px;
  display: flex;
  flex-flow: column;
  background-color: #fff;
  -webkit-box-shadow: 0 10px 10px -5px rgba(0,0,0,0.3);
  box-shadow: 0 10px 10px -5px rgba(0,0,0,0.3);
}
#results {
  -webkit-box-shadow: none;
  box-shadow: none;
}

#keyword {
    border: 1px solid #BBB;
    border-radius: 5px;
    line-height: 1,5em;
    padding: 5px 15px;
}

#keySearch label, #stepSearch label {
    font-weight: 500;
    font-size: 14px;
}
#keySearch label {
  display: inline-block;
  margin: 10px 0 5px;
}
#keySearch label:first-of-type {
  margin-top: 0;
}

#keySearch h2, #stepSearch h2 {
    width: 100%;
    margin: 0 0 15px;
}

/* キーワード検索 */
#keyword-list {
  display: block;
  margin: -3px;
}
#keyword-list > li {
  padding: 3px;
  display: inline-block;
}

#keyword-list button {
  display: inline-block;
  color: #333;
  font-size: 13px;
  font-weight: 300;
  padding: 6px 24px;
  background: #f3f3f3;
  border: 1px solid transparent;
  border-radius: 2px;
}
#keyword-list button:hover {
  border-color: #333;
}

#keySearch .Search {
    width: 80%;
}

#keySearch.Search.50wh {
    width: 50%;
}

#keyword-list + [class*="btnarea"] {
  margin: 30px 0 0;
}

/* ３ステップ検索 */
#stepSearch {
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
}

#stepSearch p {
    font-weight: 500;
    font-size: 14px;
    flex: 1 0 100%;
}

#stepSearch label.left {
    font-size: 11px;
    background-color: #333;
    padding: 3px;
    flex: 0 0 80px;
    margin: 0 16px 0 0;
    text-align: center;
    color: #FFF;
}
#stepSearch label.left::after {
    content: "►";
    position: relative;
    display: inline-block;
    right: -18px;
    width: 0;
    height: 0;
    color: #333;
}

#stepSearch .Select {
    flex: 1 1 auto;
}

#stepSearch label.right {
    color: #aaa;
    font-size: 13px;
    border-radius: 5px;
    flex: 0 0 100%;
    margin: 3px 0 20px;
}

/* 検索結果 */
#results {
  margin: 30px 0 0;
    display: flex;
    padding: initial;
    background-color: transparent;
}

p.items-count {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 0 0 10px;
}

ul.items-list {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
}

ul.items-list li {
  width: 16.6666%;
  padding: 5px;
  display: flex;
}
@media screen and (max-width: 1600px){
  ul.items-list li {
    width: 20%;
  }
}
@media screen and (max-width: 1366px){
  ul.items-list li {
    width: 25%;
  }
}

ul.items-list li a {
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  border: 5px solid #f3f3f3;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  -webkit-box-shadow: 0 10px 10px -5px rgba(0,0,0,0.3);
  box-shadow: 0 10px 10px -5px rgba(0,0,0,0.3);
}
ul.items-list li a:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 20px 20px -5px rgba(0,0,0,0.5);
  box-shadow: 0 20px 20px -5px rgba(0,0,0,0.5);
}
ul.items-list li .img {
  max-width: 200px;
  width: 100%;
  display: block;
  margin:0 auto 20px;
}
ul.items-list li img {
  max-width: 100%;
  width: 100%;
}
ul.items-list li p {
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
ul.items-list li p.maker-name {
  font-size: 16px;
  font-weight: 900;
  margin: 0 0 10px;
}

/* Histories */
#latest-changes 	{
  padding: 40px;
  background-color: #fff;
  margin: 20px 0 0;
  -webkit-box-shadow: 0 10px 10px -5px rgba(0,0,0,0.3);
  box-shadow: 0 10px 10px -5px rgba(0,0,0,0.3);
}
#latest-changes .table_wrap {
  overflow: auto;
}
#latest-changes .table_wrap > div {
  min-height: 210px;
  width: 3000px;
  border: 2px solid #333;
  overflow: hidden;
}

#latest-changes .ReactVirtualized__Table__headerRow {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2em;
  background: #f3f3f3;
}
#latest-changes .ReactVirtualized__Table__rowColumn {
  white-space: normal;
  text-overflow: inherit;
}
.ReactVirtualized__Table__row {
  font-weight: 300;
  font-size: 12px;
}
.ReactVirtualized__Table__row + .ReactVirtualized__Table__row {
  /* border-top: 1px solid #eee; */
}
